const baseUrl = 'https://assets.nl-kompetenzzentrum.de'
export default {
  slides: [
    {
      alt: 'Kompetenz­zentrum für Niederlassungs­beratung',
      full: `${baseUrl}/img/full/_DSC6400.jpg`,
      l: {
        '1x': `${baseUrl}/img/large/_DSC6400.jpg`
      },
      m: {
        '1x': `${baseUrl}/img/medium/_DSC6400.jpg`
      },
      s: {
        '1x': `${baseUrl}/img/small/_DSC6400.jpg`
      },
      xs: {
        '1x': `${baseUrl}/img/xsmall/_DSC6400.jpg`
      }
    },
    {
      alt: 'Kompetenz­zentrum für Niederlassungs­beratung',
      full: `${baseUrl}/img/full/_DSC6371.jpg`,
      l: {
        '1x': `${baseUrl}/img/large/_DSC6371.jpg`
      },
      m: {
        '1x': `${baseUrl}/img/medium/_DSC6371.jpg`
      },
      s: {
        '1x': `${baseUrl}/img/small/_DSC6371.jpg`
      },
      xs: {
        '1x': `${baseUrl}/img/xsmall/_DSC6371.jpg`
      }
    },
    {
      alt: 'Kompetenz­zentrum für Niederlassungs­beratung',
      full: `${baseUrl}/img/full/_DSC6369.jpg`,
      l: {
        '1x': `${baseUrl}/img/large/_DSC6369.jpg`
      },
      m: {
        '1x': `${baseUrl}/img/medium/_DSC6369.jpg`
      },
      s: {
        '1x': `${baseUrl}/img/small/_DSC6369.jpg`
      },
      xs: {
        '1x': `${baseUrl}/img/xsmall/_DSC6369.jpg`
      }
    },
    {
      alt: 'Kompetenz­zentrum für Niederlassungs­beratung',
      full: `${baseUrl}/img/full/_DSC6344.jpg`,
      l: {
        '1x': `${baseUrl}/img/large/_DSC6344.jpg`
      },
      m: {
        '1x': `${baseUrl}/img/medium/_DSC6344.jpg`
      },
      s: {
        '1x': `${baseUrl}/img/small/_DSC6344.jpg`
      },
      xs: {
        '1x': `${baseUrl}/img/xsmall/_DSC6344.jpg`
      }
    },
    {
      alt: 'Kompetenz­zentrum für Niederlassungs­beratung',
      full: `${baseUrl}/img/full/_DSC6363.jpg`,
      l: {
        '1x': `${baseUrl}/img/large/_DSC6363.jpg`
      },
      m: {
        '1x': `${baseUrl}/img/medium/_DSC6363.jpg`
      },
      s: {
        '1x': `${baseUrl}/img/small/_DSC6363.jpg`
      },
      xs: {
        '1x': `${baseUrl}/img/xsmall/_DSC6363.jpg`
      }
    }
  ],
  texts: [
    {
      href: `${baseUrl}/content/home/p1.md`,
      button: [
        {
          label: 'Mehr Erfahren',
          slideLink: 1
        }
      ]
    }
  ]
}
